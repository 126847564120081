<template>
  <div class="news">
    <Title class="title-component" title="新闻资讯" />
    <img class="bg-right" src="../assets/bg-right.png" alt="">
    <ul class="news-content">
      <li>
        <div class="news-left">
          <span class="order">01</span>
          <div class="text-content">
            <h3>OpenAI董事会遭逼宫 逾九成员工要求恢复Altman职位</h3>
            <span>OpenAI投资者和逾九成员工要求恢复Altman职位，微软暗示不反对Altman回归。OpenAI逾700员工联名...</span>
          </div>
        </div>
        <span>2023-11-21</span>
      </li>
      <li>
        <div class="news-left">
          <span class="order">02</span>
          <div class="text-content">
            <h3>特斯拉中国上调Model Y长续航版售价至30.44万元</h3>
            <span>11月21日消息，特斯拉中国Model Y长续航版售价涨价2000元至30.44万元。</span>
          </div>
        </div>
        <span>2023-11-21</span>
      </li>
      <li>
        <div class="news-left">
          <span class="order">03</span>
          <div class="text-content">
            <h3>被三星电子踢出供应链？京东方：不实，供货计划依然有序推进中</h3>
            <span>11月20日，京东方（000725）对澎湃新闻记者独家回应称，近日关于京东方与三星合作问题的传闻，为...</span>
          </div>
        </div>
        <span>2023-11-21</span>
      </li>
      <li>
        <div class="news-left">
          <span class="order">04</span>
          <div class="text-content">
            <h3>10月27日正式开售，五大理由入手Find N3</h3>
            <span>11月21日消息，特斯拉中国Model Y长续航版售价涨价2000元至30.44万元。</span>
          </div>
        </div>
        <span>2023-11-21</span>
      </li>
      <li>
        <div class="news-left">
          <span class="order">05</span>
          <div class="text-content">
            <h3>小鹏汽车虚假宣传不升级xpilot4.0</h3>
            <span>小鹏P72020款670E版本，2022年2月份购买，E版本付了硬件费用，软件也付了2万元费用，但是至今Xpilot...</span>
          </div>
        </div>
        <span>2023-11-21</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Title from './TitleComponent.vue'

export default {
  components: {
    Title
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news {
  margin-top: 140px;
  padding-bottom: 142px;
  position: relative;
  overflow: hidden;
}
.bg-right {
  position: absolute;
  bottom: -1024px;
  right: 20px;
  opacity: 0.4;
}
.news-content {
  margin: 43px auto 0;
  padding: 0 58px;
  width: 942px;
  height: 566px;
  background: url(../assets/bg-news.png) no-repeat;
  background-size: 100%;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
}
.news-content li {
  display: flex;
  border-bottom: 1px solid rgba(255,255,255, 0.1);
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.news-content .news-left {
  display: flex;
}
.news-content li .order {
  width: 47px;
  height: 47px;
  border: 2px solid rgba(255,255,255,0.3);
  line-height: 47px;
  font-size: 24px;
  margin-right: 26px;
}
.news-content li h3 {
  text-align: left;
  font-size: 18px;
  color: #52A3F6;
  margin-bottom: 10px;
}
.news-content li .text-content span {
  font-size: 14px;
  opacity: 0.6;
}
</style>
