<template>
  <full-page :options="options">
    <Home class="section" ref="homeComponent"/>
    <!-- <SecondPage class="section"/> -->
    <Vrcontent class="section" ref="vrComponent"/>
    <AnimationContent class="section" ref="animationComponent"/>
    <DigitalContent class="section" ref="digitalComponent"/>
    <WeAdvantage class="section"/>
    <NewsPage class="section"/>
    <ContactPage class="section"/>
  </full-page>
</template>

<script setup>
import Home from './components/HomePage.vue'
import WeAdvantage from './components/WeAdvantage.vue'
import NewsPage from './components/NewsPage.vue'
import ContactPage from './components/ContactPage.vue'
import Vrcontent from './components/Vr-content.vue'
import AnimationContent from './components/Animation-content.vue'
import DigitalContent from './components/Digital-content.vue'

import { ref, onMounted } from 'vue';

onMounted(() => {
  // 检测用户代理字符串
  const userAgent = navigator.userAgent;

  // 定义移动设备的关键字
  const mobileKeywords = ['Android', 'iPhone', 'iPad', 'Windows Phone', 'Mobile'];

  // 判断用户代理字符串中是否包含移动设备关键字
  const isMobile = mobileKeywords.some(keyword => userAgent.includes(keyword));

  const currentUrl = window.location.href;
  console.log(currentUrl)
  // 打印结果并进行重定向
  if (isMobile) {
    console.log('移动端');
    if (! currentUrl.indexOf('mobile')) {
      console.log('已经是移动端了')
    } else {
      console.log('跳转移动端')
      window.location.href = "http://mobile.sascg.cn";
    }
  } else {
    console.log('PC端');
    if (currentUrl.indexOf('www')) {
      console.log('已经是pc端了')
    } else {
      console.log('跳转pc端')
      window.location.href = "http://www.sascg.cn";
    }
  }
});

const homeComponent = ref(null);
const vrComponent = ref(null);
const animationComponent = ref(null);
const digitalComponent = ref(null);

const options = {
  afterLoad: afterLoad,
  navigation: true,
  anchors: ['first', 'second', 'third', 'fouth']
};

function afterLoad(anchorLink, index) {
  if (index.anchor === 'first' && homeComponent.value) {
    homeComponent.value.handelUpdate()
  }
  else if (index.anchor === 'second' && vrComponent.value) {
    vrComponent.value.handelUpdate()
  }
  else if (index.anchor === 'third' && animationComponent.value) {
    animationComponent.value.handelUpdate()
  }
  else {
    digitalComponent.value.handelUpdate()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background-color: #030921;
  padding: 0;
  margin: 0;
}
body, p {
  margin: 0;
}
ul,li {
  list-style: none;
  padding: 0;
}
h1, h3 {
  margin: 0;
}
</style>
