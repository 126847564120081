<template>
  <div class="title">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: ['title'],
  // setup(props) {
  //   console.log(props.title)
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
}
.title h1 {
  display: inline;
  padding-bottom: 18px;
  font-size: 38px;
  letter-spacing: 8px;
  background-image: url(../assets/title-underline.png);
  background-size: 100% 12%;
  background-repeat: no-repeat;
  background-position: -10px bottom;
}
</style>
