<template>
  <div class="service-items">
    <img class="bg-right" src="../assets/bg-right.png" alt="">
    <div class="service-items-content">
      <!-- digital-content -->
      <!-- <Title class="title-component" title="服务项目" /> -->
      <img class="service-item-title" src="../assets/font-digital.png" alt="">
      <div class="service-item-video">
        <video
        ref="videoElement"
        src="http://sascg-bucket.oss-cn-beijing.aliyuncs.com/2023_5_1_iteration_2.webm"
        autoplay="true"
        volume="0"
        loop="true"
        @timeupdate="handleTimeUpdate"
        ></video>

        <div class="digital-text">
          <!-- <img class="bg-right" src="../assets/bg-right.png" alt="">
          <div class="service-item-left">
            <img class="service-item-title" src="../assets/font-vr.png" alt="">
            <img class="service-item-img" src="../assets/vr.png" alt="">
          </div>
          <div class="service-item-right">
            <img class="service-item-text" src="../assets/font-virtual-world.png" alt="">
            <img class="service-item-tag" src="../assets/vr-text.png" alt="">
          </div> -->
          <h3>建筑表现案例</h3>
          <p>拔地而起，施工难点，光影交织，完美呈现</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import Title from './TitleComponent.vue'

const videoElement = ref(null);

// 滑动到当前组件的时候继续播放视频
let handelUpdate = () => {
  videoElement.value.play()
}

defineExpose({
  handelUpdate,
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-component {
  margin-top: 46px;
}
.bg-right {
  position: absolute;
  top: -600px;
  right: 0;
}
.service-item-title {
  margin-top: 10vh;
}
.service-items-content {
  height: 100vh;
  overflow: hidden;
}
.digital-text {
  background-color: #030921;
  box-sizing: border-box;
  padding: 40px;
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 423px;
  height: 300px;
  text-align: left;
}
.digital-text h3 {
  margin-bottom: 20px;
}
.digital-text p {
  margin-bottom: 10px;
}
.service-item-video {
  position: relative;
  width: 100%;
  height: 544px;
  margin-top: 10vh;
  /* transform: translate(0, -50%); */
}
.service-item-video video {
  width: 100%;
  height: 544px;
  object-fit: fill;
}

</style>
