<template>
  <div class="service-items">
    <img class="bg-right" src="../assets/bg-right.png" alt="">
    <div class="service-items-content">
      <!-- animation-content -->
      <!-- <Title class="title-component" title="服务项目" /> -->
      <img class="service-item-title" src="../assets/font-animation.png" alt="">
      <div class="service-item-video">
        <video
        ref="videoElement"
        src="http://sascg-bucket.oss-cn-beijing.aliyuncs.com/2023_5_1_iteration_2.webm"
        autoplay="true"
        volume="0"
        loop="true"
        @timeupdate="handleTimeUpdate"
        ></video>

        <div class="animation-text">
          <!-- <img class="bg-right" src="../assets/bg-right.png" alt="">
          <div class="service-item-left">
            <img class="service-item-title" src="../assets/font-vr.png" alt="">
            <img class="service-item-img" src="../assets/vr.png" alt="">
          </div>
          <div class="service-item-right">
            <img class="service-item-text" src="../assets/font-virtual-world.png" alt="">
            <img class="service-item-tag" src="../assets/vr-text.png" alt="">
          </div> -->
          <h3>工业三维动画</h3>
          <h5>辅助设计，合理布局，工艺流程再实现。</h5>
          <p><i></i>TCL中环内蒙五期</p>
          <p><i></i>TCL中环银川六期</p>
          <p><i></i>TCL中环DW3、4、5期</p>
          <p><i></i>环晟新能源宜兴、天津、内蒙</p>
          <p><i></i>LumeTech-KSA</p>
          <p><i></i>LumeTech-USA</p>
        </div>
        <div class="useto-text">
          <h3>应用领域：医疗、智慧工厂、教育、军工</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import Title from './TitleComponent.vue'

const videoElement = ref(null);

// 滑动到当前组件的时候继续播放视频
let handelUpdate = () => {
  videoElement.value.play()
}

defineExpose({
  handelUpdate,
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-right {
  position: absolute;
  top: -600px;
  left: -600px;
}
.service-items-content {
  height: 100vh;
  overflow: hidden;
}
.service-item-title {
  margin-top: 10vh;
}
.animation-text {
  background-color: #030921;
  box-sizing: border-box;
  padding: 40px;
  position: absolute;
  bottom: 0;
  right: 20%;
  width: 423px;
  height: 300px;
  text-align: left;
}
.animation-text h3 {
  margin-bottom: 10px;
}
.animation-text h5 {
  margin-top: 0;
  margin-bottom: 20px;
}
.animation-text p {
  margin-bottom: 10px;
}
.animation-text p i {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}
.useto-text {
  position: absolute;
  left: 20%;
  bottom: 5vh;
  color: #fff;
}
.service-item-video {
  position: relative;
  width: 100%;
  height: 544px;
  margin-top: 10vh;
  /* transform: translate(0, -50%); */
}
.service-item-video video {
  width: 100%;
  height: 544px;
  object-fit: fill;
}

</style>
