<template>
  <div class="sas">
    <img class="bg-right" src="../assets/bg-right.png" alt="">
    <header>
      <img class="logo" src="../assets/logo.png" alt="">
      <ul class="menu">
        <li><a href="javascript:;">主页</a></li>
        <li><a href="javascript:;">关于我们</a></li>
        <li><a href="javascript:;">服务项目</a></li>
        <li><a href="javascript:;">团队介绍</a></li>
        <li><a href="javascript:;">联系我们</a></li>
      </ul>
    </header>
    <div class="video">
        <video
          ref="videoElement"
          src="http://sascg-bucket.oss-cn-beijing.aliyuncs.com/2023_5_1_iteration_2.webm"
          autoplay="true"
          volume="0"
          loop="true"
          @timeupdate="handleTimeUpdate"
        ></video>
    </div>
    <div class="down-ward">
      <a href="javascript:;">
        <img src="../assets/downward.png" alt="">
        <span>向下滑动</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const videoElement = ref(null);

// 滑动到当前组件的时候继续播放视频
let handelUpdate = () => {
  videoElement.value.play()
}

defineExpose({
  handelUpdate,
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sas {
  position: relative;
  z-index: 2;
  height: 100vh;
}
.bg-right {
  position: absolute;
  top: -600px;
  right: 0;
  z-index: -1;
}
header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 248px;
  height: 40px;
  margin: 30px 0 0 44px;
}
.menu {
  display: flex;
  margin: 45px 55px 0 0;
}
.menu li {
  margin-left: 80px;
  font-size: 14px;
}
.menu li a {
  color: #fff;
  text-decoration: none;
}
.video {
  width: 100%;
  height: 100%;
  background-color: #040B29;
  overflow: hidden;
}
.video video {
  width: 100%;
  height: 100%;
  /* object-fit: fill; */
}
.video-control {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 50px;
  display: none;
}
.main-text {
  position: absolute;
  bottom: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 54px;
}
.down-ward {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
.down-ward a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
.down-ward a img {
  width: 30px;
  height: 28px;
  margin-bottom: 26px;
}
</style>
