<template>
  <div class="contact">
    <Title class="title-component" title="联系我们" />
    <div class="contact-content">
      <div class="map">
        <div id="container" style="height: 100%; width: 100%"></div>
        <div class="company-infomation">
          <div @click="openAmapApp"><span class="icon-location"></span>陕西省西安市雁塔区旺座曲江F座1804室</div>
          <div><span class="icon-phone"></span>（+86）13891966516 周知（ZLASH CHOU）</div>
        </div>
      </div>
      <p class="copyright">Copyright © 2012-2023 形而上数字科技有限公司 版权所有 非商业授权 Powered by Efcms  陕ICP备2024028300号-1</p>
    </div>
  </div>
</template>

<script setup>
import Title from './TitleComponent.vue'
import AMapLoader from "@amap/amap-jsapi-loader";
import pic1 from '../assets/map-text.png'

//进行地图初始化 key:5aa59281874aa79189994eeff628ee5f
function initMap() {
  AMapLoader.load({
    key: "5aa59281874aa79189994eeff628ee5f", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  })
    .then((AMap) => {
      const map = new AMap.Map("container", {
        //设置地图容器id
        viewMode: "3D", //是否为3D地图模式
        zoom: 17, //初始化地图级别
        center: [109.015487, 34.210891], //初始化地图中心点位置
      });
      //点标记显示内容
      const markerContent = `<div class="custom-content-marker">
        <img src="`+pic1+`" style="opacity:0.5"/>
        <div class="map-text" style="position:absolute;top:50%;left:7%;transform:translate(0,-75%);font-size:18px;letter-spacing:3px;">旺座曲江F座1804</div>
        </div>`
      const position = new AMap.LngLat(109.015487, 34.210891); //Marker 经纬度
      const marker = new AMap.Marker({
        position: position,
        content: markerContent, //将 html 传给 content
        offset: new AMap.Pixel(0, -55), //以 icon 的 [center bottom] 为原点
      });
      //添加插件
      AMap.plugin(["AMap.ToolBar", "AMap.Scale", "AMap.HawkEye"], function () {
        //异步同时加载多个插件
        // map.addControl(new AMap.HawkEye()); //显示缩略图
        map.add(marker);
        // map.addControl(new AMap.Scale()); //显示当前地图中心的比例尺
      });
    })
    .catch((e) => {
      console.log(e);
    });
}
initMap();

const latitude = 34.210891; // 纬度
const longitude = 109.015487; // 经度
const appName = "高德地图"; // 应用名称
const name = "西安形而上数字科技有限公司"; // 地点名称
const url = `https://uri.amap.com/navigation?我的位置&to=${longitude},${latitude},${name}&mode=car&policy=1&src=com.mzwu.www&callnative=1`

const openAmapApp = () => {
  window.open(url)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact {
  background-color: #000;
  padding: 67px 0 33px 0;
}
.map {
  margin: 46px auto 0;
  width: 1051px;
  height: 344px;
  position: relative;
}

.custom-content-marker {
  position: relative;
  width: 206px;
  height: 51px;
}

.custom-content-marker img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-content-marker .map-text {
  font-size: 18px;
}

.copyright {
  margin-top: 200px;
}

.company-infomation {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
  color: #afafaf;
  font-size: 20px;
}

.company-infomation div {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.company-infomation .icon-location {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: url(../assets/icon-location.png) no-repeat;
  background-size: 100%;
}

.company-infomation .icon-phone {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  background: url(../assets/icon-phone.png) no-repeat;
  background-size: 100%;
}
</style>
