<template>
  <div class="we-advantage">
    <Title class="title-component" title="我们的优势" />
    <img class="bg-right" src="../assets/bg-right.png" alt="">
    <ul class="advantage-content">
      <li>
        <img src="../assets/icon-1v1server.png" alt="">
        <h3>专业一对一服务</h3>
        <span>专业的团队服务，一对一即时咨询服务</span>
      </li>
      <li>
        <img src="../assets/icon-brand.png" alt="">
        <h3>首屈一指的品牌</h3>
        <span>成功培育了金融服务行业首屈一指的品牌</span>
      </li>
      <li>
        <img src="../assets/icon-gene.png" alt="">
        <h3>独特的国际化基因</h3>
        <span>有独特国际化特色的商务服务</span>
      </li>
      <li>
        <img src="../assets/icon-capacity.png" alt="">
        <h3>名列前茅的创新力</h3>
        <span>提供创新完善的产品和高质量的特色服务</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Title from './TitleComponent.vue'

export default {
  components: {
    Title
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.we-advantage {
  position: relative;
}
.bg-right {
  position: absolute;
  top: -660px;
  left: -550px;
  opacity: 0.4;
}
.advantage-content {
  margin: 62px auto 0;
  width: 1058px;
  height: 701px;
  border-radius: 18px;
  background: url(../assets/bg-advantage.png) no-repeat;
  background-size: 100%;
  display: flex;
  flex-wrap: wrap;
}
.advantage-content li {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  justify-content: center;
}
.advantage-content li img {
  width: 64px;
  height: 64px;
}
.advantage-content li h3 {
  margin-top: 34px;
  margin-bottom: 20px;
  font-size: 22px;
}
.advantage-content li span {
  opacity: 0.7;
}
</style>
